import { AbstractControl } from '@angular/forms';
import { AbsenteeDay } from '@reducers/orm/absence/absence.model';
import { differenceInDays, format, isBefore } from 'date-fns';

import { TrackingService } from '../../../services/tracking.service';
import { AbsenceDayOption } from '../employee-absentee-days/employee-absentee-days.enum';
import { DayValue } from '../employee-absentee-days/employee-absentee-days.model';
import { AbsencePeriodValue, AbsenceRequestAction, AbsenceRequestOriginator } from './absence-request.models';

export const getTrackEventFn =
  (trackingService: TrackingService, originator: AbsenceRequestOriginator, action: AbsenceRequestAction) =>
  (event, props = {}) => {
    props = {
      ...props,
      action,
      originator,
    };
    trackingService.event(event, props);
  };

export const DEFAULT_DAY_TIME = '12:00';

export const totalHours = (hours: (number | string)[]): number =>
  hours.map((hour) => (typeof hour === 'string' ? parseFloat(hour) : hour)).reduce((sum, curr) => (sum += curr), 0);

export const totalDays = (absenceDayTypes: AbsenceDayOption[]): number =>
  absenceDayTypes.reduce((sum, type) => {
    if (type === AbsenceDayOption.FULL) {
      return sum + 1;
    }
    if (type === AbsenceDayOption.HALF_FROM || type === AbsenceDayOption.HALF_UNTIL) {
      return sum + 0.5;
    }
    return sum;
  }, 0);

export const totalWaitHours = (amount: number, hours: number[]): number => {
  if (amount === 0) {
    return amount;
  }
  return hours.reduce((acc, curr) => {
    const hours = curr;
    if (amount > 0 && hours > 0) {
      acc += hours;
      amount -= 1;
    }
    return acc;
  }, 0);
};

export const getPeriod = (period: AbsencePeriodValue): string[] => {
  if (Array.isArray(period)) {
    return period;
  }

  if (isBefore(new Date(period), new Date())) {
    return [period, format(new Date(), 'yyyy-MM-dd')];
  } else {
    return [period, period];
  }
};

export function isPeriodValid(control?: AbstractControl) {
  return control && (control.valid || control.hasError('absenceConflict'));
}

export function getAbsenceDayOption(dayValue: DayValue) {
  const days = parseFloat(`${dayValue.days}`);
  switch (days) {
    case 0: {
      return AbsenceDayOption.NONE;
    }
    case 1: {
      return AbsenceDayOption.FULL;
    }
    case 0.5: {
      return dayValue.until_time ? AbsenceDayOption.HALF_UNTIL : AbsenceDayOption.HALF_FROM;
    }
  }
  return dayValue.hours === 0 ? AbsenceDayOption.NONE : AbsenceDayOption.FULL;
}

export function getPeriodControlValue(openEnded, period): { newValue: AbsencePeriodValue; emitEvent: boolean } {
  period = getPeriod(period);
  let newValue;
  const perviousDiff = differenceInDays(new Date(period[0]), new Date(period[1]));
  let currentDiff = 0;
  const startsInThePast = period[0] < format(new Date(), 'yyyy-MM-dd');

  if (openEnded) {
    newValue = period[0];
  } else {
    newValue = [period[0], period[1]];
    currentDiff = differenceInDays(new Date(newValue[0]), new Date(newValue[1]));
  }

  return {
    newValue,
    emitEvent: perviousDiff !== currentDiff || startsInThePast,
  };
}

export const applyWaitDaysToAbsenceDays = (formData, hasWaitHours = false): AbsenteeDay[] => {
  let waitDays = formData.wait_days;

  return formData.days.map((day) => {
    if (day.absenceDayOption) {
      const refactoredDay: Partial<AbsenteeDay> = {
        date: day.date,
        time_off_balance_id: day.time_off_balance_id,
      };
      if (day.absenceDayOption === AbsenceDayOption.FULL) {
        return { ...refactoredDay, days: 1 };
      }
      if (day.absenceDayOption === AbsenceDayOption.HALF_UNTIL) {
        return { ...refactoredDay, days: 0.5, until_time: day.day_time || DEFAULT_DAY_TIME };
      }
      if (day.absenceDayOption === AbsenceDayOption.HALF_FROM) {
        return { ...refactoredDay, days: 0.5, from_time: day.day_time || DEFAULT_DAY_TIME };
      }
      return { ...refactoredDay, days: 0 };
    }

    const { isWeekend, ...rest } = day;
    const refactoredDay: Partial<AbsenteeDay> = {
      ...rest,
      start_time: day.start_time ? day.start_time : '00:00:00',
    };

    if (hasWaitHours && waitDays > 0 && day.hours > 0) {
      refactoredDay['wait_hours'] = day.hours.toString();
      waitDays -= 1;
    } else {
      refactoredDay['wait_hours'] = '0.00000';
    }

    return refactoredDay;
  });
};
